<template>

  <div style="width:-webkit-fill-available;">
      <b-card>
        <h3 style="text-align:center">농정원 데이터 '일회성' 수동 연계</h3>
        <br>
        <div >
            <b-input-group prepend="스마트팜 아이디" class="mt-1">
                <b-form-input v-model="smartFarmId" placeholder="필수 입력"></b-form-input>
            </b-input-group>
            <b-input-group prepend="축산업 등록번호" class="mt-1">
                <b-form-input v-model="registNo" placeholder="필수 입력"></b-form-input>
            </b-input-group>
            <b-form-radio-group
                v-model="item"
                :options="itemOptions"
                class="mt-1"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
            ></b-form-radio-group>
            
            <b-form-radio-group
                v-model="stallType"
                :options="stallTypeOptions"
                :state="state"
                class="mt-1"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
            ></b-form-radio-group>
            <hr>
            <!-- <b-input-group prepend="Item Code" class="mt-1">
                <b-form-input v-model="item" disabled></b-form-input>
            </b-input-group> -->
           
            <b-form-radio-group
                v-model="device"
                :options="deviceOptions"
                class="mt-1"
                value-field="item"
                text-field="name"
                disabled-field="notEnabled"
            ></b-form-radio-group>
            <div v-if="device === 'EC01'">
                <b-input-group :prepend="`출력 ${devicePower}%` " class="mt-1">
                    <b-form-input id="range-1" v-model="devicePower" type="range" min="0" step="5" max="100"></b-form-input>
                </b-input-group>
                <b-input-group :prepend="`회전수 ${deviceRPM}RPM`" class="mt-1">
                    <b-form-input v-model="deviceRPM" placeholder="필수 입력"></b-form-input>
                </b-input-group>
            </div>
            <div v-if="device === 'ES01'">
                <b-input-group :prepend="`온도 ${temperature}℃`" class="mt-1">
                    <b-form-input v-model="temperature" placeholder="필수 입력"></b-form-input>
                </b-input-group>
            </div>
            <div v-if="device === 'ES02'">
                <b-input-group :prepend="`습도  ${humidity}％`" class="mt-1">
                    <b-form-input v-model="humidity" placeholder="필수 입력"></b-form-input>
                </b-input-group>
            </div>
            <div v-if="device === 'AF12'">
                <b-input-group :prepend="`사료량  ${feedCount}Kg`" class="mt-1">
                    <b-form-input v-model="feedCount" placeholder="필수 입력"></b-form-input>
                </b-input-group>
            </div>
            <p>※ 온도, 습도, 회전수나 출력값은 소숫점 첫번째까지인 실수 또는 정수만 입력됩니다. ※</p>
             
            <b-button variant="outline-warning" class="mt-1" size="sm" style= "float:right" @click="check()">연계</b-button>
        </div>
      </b-card>
    
  </div>
</template>
<script>
import {
    BButton, BButtonGroup, BDropdown, BFormInput, BInputGroup, BCard, BFormRadioGroup 
} from 'bootstrap-vue'
import store from '@/store'
import moment from 'moment'
export default {
components: {
    BCard,
    BButton,
    BFormInput,
    BInputGroup,
    BButtonGroup,
    BDropdown,
    BFormRadioGroup 
  },
  data: () => {
      return {
          registNo: '',
          item: 'W00',
          smartFarmId: '',
          device: 'ES01',
          stallType: 'SW01',
          stallTypeOptions: '',
          equipNo: 'A',
          devicePower: '0',
          deviceRPM: '0',
          temperature: '0',
          humidity: '0',
          feedCount: '0',
          itemOptions: [
                { item: 'W00', name: '한우' },
                { item: 'D00', name: '낙농' },
                /* { item: 'P00', name: '양돈', notEnabled: true }, */
                /* { item: 'H00', name: '양계', notEnabled: true }, */
                /* { item: 'C00', name: '공통', notEnabled: true }, */
          ],
          deviceOptions: [
                { item: 'ES01', name: '온도' },
                { item: 'ES02', name: '습도' },
                /* { item: 'ES03', name: '이산화탄소', notEnabled: true},
                { item: 'ES04', name: '암모니아', notEnabled: true},
                { item: 'ES05', name: '조도', notEnabled: true },
                { item: 'ES06', name: '풍향', notEnabled: true },
                { item: 'ES07', name: '강우', notEnabled: true },
                { item: 'ES12', name: '황화수소', notEnabled: true }, */
                { item: 'EC01', name: '송풍팬' },
                /* { item: 'EC04', name: '윈치커튼', notEnabled: true },
                { item: 'EC09', name: '안개분무기', notEnabled: true },
                { item: 'PC08', name: '원유냉각기(낙농)', notEnabled: true },*/
                { item: 'AF12', name: '사료급이기' }, 
          ],
            
      }
  },
  computed: {
      state() {
          if(this.item !== null) {
              switch (this.item) {
                    case 'W00':
                        this.stallTypeOptions = [
                            { item: 'SC01', name: '외부(W)' },
                            { item: 'SW01', name: '우사(W)' },
                            { item: 'SW02', name: '착유장(W)' },
                        ]
                        break;
                    case 'D00':
                        this.stallTypeOptions = [
                            { item: 'SC01', name: '외부(D)' },
                            { item: 'SD01', name: '우사(D)' },
                            { item: 'SD02', name: '착유장(D)' },
                        ]
                        break;
                    default:
                        break;
                }
          }
      },
  },
  methods: {
      async check () {
          if(this.registNo === '' || this.item === '' || this.smartFarmId === '' || this.device === '' || this.stallType === '') {
              alert('모든 데이터를 입력하세요!')
          } else {
              if(this.device === 'EC01' && this.devicePower === '') {
                  alert('출력 데이터와 회전수를 입력하세요')
              } else if(this.device === 'ES01' && this.temperature === '') {
                  alert('온도 데이터를 입력하세요')
              } else if (this.device === 'ES02' && this.humidity === '') {
                  alert('습도 데이터를 입력하세요')
              } else {
                  if(this.device === 'EC01') {
                    this.temperature = ''
                    this.humidity = ''
                  } else if(this.device === 'ES01') {
                    this.devicePower = ''
                    this.deviceRPM = ''
                    this.humidity = ''
                  } else if(this.device === 'ES02'){
                    this.devicePower = ''
                    this.deviceRPM = ''
                    this.temperature = ''
                  } else {
                    this.devicePower = ''
                    this.deviceRPM = ''
                    this.temperature = ''
                    this.humidity = ''
                  }
                  console.log({
                    devicePower: this.devicePower,
                    deviceRPM: this.deviceRPM,
                    temperature: this.temperature,
                    humidity: this.humidity,
                  })
                    let queryBody = {
                        LSIND_REGIST_NO: this.registNo.toUpperCase(),
                        ITEM_CODE: this.item,
                        MAKR_ID: this.smartFarmId.toLowerCase(),
                        EQPMN_CODE: this.device,
                        EQPMN_NO: (this.device === 'EC01')? 'A': '1',
                        STALL_TY_CODE: this.stallType,
                        STALL_NO: '01',
                        MESURE_DT: moment().format('YYYYMMDD_HHmmss'),
                        MESURE_VAL_01: (this.device === 'AF12')?'': parseFloat(this.devicePower || this.temperature || this.humidity).toFixed(1),
                        MESURE_VAL_02:  parseFloat(this.deviceRPM || this.feedCount).toFixed(1) || '',
                    }
                    let result = await store.dispatch('users/setSample', {queryBody})
                    if(result.status === 200) {
                        alert((result.data)? '연계 성공': '연계 실패')
                        //location.href='/data'
                    } else {
                        alert('연계 실패')
                    }
              }
              //let result = await axios.post(``, data)
          }
      }
  },
}
</script>
<style lang="" scoped>

</style>